<template>
  <div
    class="a-checkbox"
    @click="HandleClick"
    :class="{ checked: value || partialSelect }"
  >
    <font-awesome-icon
      class="a-checkbox_icon"
      :icon="['fas', partialSelect ? 'minus' : 'check']"
    />
  </div>
</template>

<script>
export default {
  name: 'ACheckbox',
  props: {
    value: {
      default: false
    },
    partialSelect: {
      default: false
    },
    readonly: {
      default: false
    }
  },
  methods: {
    HandleClick() {
      if (this.readonly) {
        return
      }
      let v = !this.value
      if (this.partialSelect) {
        v = false
      }
      this.$emit('input', v)
      this.$emit('change', v)
    }
  }
}
</script>

<style lang="scss" scoped>
.a-checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #858585;
  color: #858585;
  border-radius: 2px;
  font-size: 11px;
  user-select: none;
  .a-checkbox_icon {
    display: none;
  }
  &.checked {
    background: #2dabe2;
    color: #fff;
    .a-checkbox_icon {
      display: inline-block;
    }
  }
  &.parcial {
    background: #2dabe2;
    color: #fff;
    .a-checkbox_icon {
      display: inline-block;
    }
  }
}
</style>
